"use client";
import { Font, Modal, ModalHandle } from "@hkexpressairwayslimited/ui";
import { Stack } from "@mui/material";
import { ErrorType } from "lib/features/flight-book/payment/definition";
import { useTransContent } from "modules/common/trans-content/transContent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanErrorModalDetail, selectErrorModalDetail } from "store/sessionStorage/slices/errorModalDetail";

export const MessageErrorModal = () => {
  const { t } = useTransContent();
  const {
    desc,
    title,
    onClose,
    type,
    data: { trace_id } = { trace_id: "" },
  } = useSelector(selectErrorModalDetail) ?? {};
  const [ref, setFef] = useState<ModalHandle | null>();
  const dispatch = useDispatch();
  useEffect(() => {
    if (desc && ref) {
      ref.open();
    }
  }, [desc, ref]);

  return (
    type === ErrorType.M &&
    title &&
    desc && (
      <Modal
        disabledBackDropClose={true}
        noCrossIcon={true}
        title={t(title)}
        onClose={() => {
          ref?.close();
          dispatch(cleanErrorModalDetail());
          onClose && onClose();
        }}
        ref={setFef}
      >
        <Stack rowGap={2} direction='column'>
          <Font>{t(desc)}</Font>
          {trace_id && <input type='hidden' id='api_response_trace_id' value={trace_id} />}
        </Stack>
      </Modal>
    )
  );
};
